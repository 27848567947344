import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import axios from "axios"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import NewestVideos from "../components/newestVideos"
import VlogVideos from "../components/vlogVideos"
import SEO from "../components/seo"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LineShareButton,
} from "react-share"
import { FaFacebook, FaWhatsapp, FaTwitter, FaLine } from "react-icons/fa"

const VideoTemplatePage = props => {
  const {
    data: { video },
    data: { relatedVideos },
  } = props
  const linkPage = `${props.location.protocol}//${props.location.host}${props.location.pathname}`

  const relatedVideosData = relatedVideos.edges.map(video => {
    const slug = video.node.title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/---/g, "-")
      .replace(/-&-/g, "-")
      .replace(/,/g, "")
      .replace(".", "")
      .replace(/"/g, "")
      .replace(/!/g, "")
      .replace(/\?\?/g, "")
      .replace("stress?", "stress")
      .replace("bercerai?", "bercerai")
      .replace("sorga-?", "sorga")
      .replace(/'/g, "")
      .replace(/:/g, "")
      .replace(/&/g, "-and-")
      .replace("-?", "")
      .replace(/#/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "")
    return (
      <div
        key={`${video.node.videoId}`}
        className="col-12 col-md-6 col-lg mt-4"
      >
        <Link
          className="text-decoration-none text-dark"
          to={`/60-seconds/${slug}`}
        >
          <div
            className="position-relative w-100"
            style={{ paddingTop: "56.25%" }}
          >
            <Img
              className="position-absolute w-100 rounded shadow"
              style={{
                height: "100%",
                top: "0",
                left: "0",
              }}
              fluid={video.node.localThumbnail.childImageSharp.fluid}
            />
          </div>
          <div className="mt-3" />
          <h6>{video.node.title}</h6>
        </Link>
      </div>
    )
  })

  return (
    <React.Fragment>
      <SEO title={video.title} />
      <Layout>
        <div className="container">
          <h1 className="display-4 text-break text-center">{video.title}</h1>
          <div className="my-5" />
          <div
            className="position-relative w-100"
            style={{ paddingTop: "56.25%" }}
          >
            <iframe
              className="position-absolute w-100 rounded shadow-lg"
              style={{ top: "0", left: "0", height: "100%" }}
              src={`https://www.youtube.com/embed/${video.videoId}`}
              frameBorder="0"
            ></iframe>
          </div>
          <div className="my-5" />
          <p>{video.description}</p>
          <div className="my-5" />
          <div className="d-flex justify-content-center">
            <FacebookShareButton className="d-inline mx-2" url={linkPage}>
              <FaFacebook size={"2rem"} />
            </FacebookShareButton>
            <WhatsappShareButton className="d-inline mx-2" url={linkPage}>
              <FaWhatsapp size={"2rem"} />
            </WhatsappShareButton>
            <TwitterShareButton url={linkPage} className="d-inline mx-2">
              <FaTwitter size={"2rem"} />
            </TwitterShareButton>
            <LineShareButton url={linkPage} className="d-inline mx-2">
              <FaLine size={"2rem"} />
            </LineShareButton>
          </div>
          <div className="my-5" />
        </div>
        <div className="bg-light py-5">
          <div className="container">
            <h3>Related Topics</h3>
            <div className="row">
              {relatedVideosData.length !== 0 ? (
                relatedVideosData
              ) : (
                <VlogVideos />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default VideoTemplatePage

VideoTemplatePage.defaultProps = {}

export const query = graphql`
  query($videoId: String, $keyword: String) {
    video: youtubeVideo(videoId: { eq: $videoId }) {
      title
      description
      videoId
    }
    relatedVideos: allYoutubeVideo(
      limit: 4
      filter: {
        title: { regex: $keyword }
        channelTitle: { eq: "Gilbert Lumoindong TV" }
      }
    ) {
      edges {
        node {
          videoId
          title
          localThumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
